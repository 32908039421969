import React, {
  useContext,
  useMemo,
  useState,
  FC,
  ReactText,
  SyntheticEvent,
} from 'react';
import { Trans } from 'react-i18next';
import formatDate from 'date-fns/format';
import { Button, Grid, Tabs, Tab, Box } from '@mui/material';
import { useSnackbar } from 'notistack';

import { TabPanelDetail } from './TabPanelDetail';
import { AlertDialog } from '../AlertDialog';
import { PaymentDialog } from '../PaymentDialog';
import { StickerDialog } from '../StickerDialog';
import { GuideDialog } from '../GuideDialog';
import { GuideDetailDialog } from '../GuideDetailDialog';
import { Dialog } from '../../Dialog';
import { CustomTabPanel, a11yProps } from '../../CustomTabPanel';
import {
  EmployeesContext,
  HardwareContext,
  UserContext,
  CompanyContext,
} from '../../../contexts';
import { RentalHistory, Hardware } from '../../../commonTypes';
import {
  RevisionDialog,
  BorrowDialog,
  EditHwDialog,
  RemoveDialog,
  FileDialog,
} from '../../../hardware';
import { isServerLicense } from '../../../utils';

interface DetailHardwareDialogProps {
  history: RentalHistory[];
  onClose?: () => void;
  hwId?: string;
  editKey?: string;
}

const gridSx = { padding: '5px 0' };

export const DetailHardwareDialog: FC<DetailHardwareDialogProps> = (props) => {
  const { hwId, onClose, editKey } = props;
  const { userData } = useContext(UserContext);
  const { setHardwareUpdate, globalHardware } = useContext(HardwareContext);
  const { employees = [] } = useContext(EmployeesContext);
  const { globalUserCompany = [], setCompanyUpdate } =
    useContext(CompanyContext);

  const [hardware, setHardware] = useState<{ data: Hardware[] }>({ data: [] });
  const [value, setValue] = useState(0);
  const [openFileDialog, setOpenFileDialog] = useState(false);
  const [openRevision, setOpenRevision] = useState(false);
  const [showRemoveDialog, setShowRemoveDialog] = useState<ReactText>();
  const [showBorrowDialog, setShowBorrowDialog] = useState(false);
  const [showReturnDialog, setShowReturnDialog] = useState(false);
  const [showEditHwDialog, setShowEditHwDialog] = useState(false);
  const [showPaymentDialog, setShowPaymentDialog] = useState(false);
  const [openSticker, setOpenSticker] = useState(false);
  const [stickerValue, setStickerValue] = useState('');
  const [showGuideDialog, setShowGuideDialog] = useState(false);
  const [openGuideDialog, setOpenGuideDialog] = useState(false);

  const { enqueueSnackbar } = useSnackbar();

  const detail = globalHardware.find((gh: Hardware) => gh?._id === hwId);

  const handleChange = (_event: SyntheticEvent, newValue: number) => {
    setValue(newValue);
    setHardwareUpdate(true);
  };

  const employee = useMemo(() => {
    let employeesOptions: { [key: string]: string } = {};
    if (employees?.length) {
      employees.forEach((em) => {
        if (em?._id)
          employeesOptions[em._id] = em.firstName + ' ' + em.lastName;
      });
    }

    if (detail) {
      return detail.borrowedBy && employeesOptions[detail.borrowedBy];
    }
    return null;
  }, [employees, detail]);

  let detailCompany;
  if (detail && detail.companyId) {
    detailCompany = globalUserCompany.find(
      (comp) => comp._id === detail.companyId,
    );
  }

  const closeAlertDialog = (index?: ReactText) => {
    setShowRemoveDialog(index);
  };

  const handleDeleteItem = async (row: Hardware) => {
    if (row.borrowedBy) {
      enqueueSnackbar(<Trans i18nKey="notifications.deviceBorrowed" />, {
        variant: 'error',
      });
      return;
    }
    const deletedID = [row._id];
    const _hardware = [...hardware.data];
    const updatedData = _hardware.filter((h) => h._id !== row._id);

    try {
      if (deletedID.length) {
        await fetch(
          `/api/hardware?ids=${encodeURIComponent(deletedID.join(','))}`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${userData!.user.token}`,
            },
            method: 'DELETE',
          },
        );
        enqueueSnackbar(<Trans i18nKey="notifications.deleted" />, {
          variant: 'success',
        });
      }
    } catch (exception) {
      console.log(exception);

      enqueueSnackbar(
        <Trans i18nKey="notifications.unexpectedErrorWhenDeleting" />,
        { variant: 'error' },
      );
    }
    setHardware({ data: updatedData });
  };

  const isEditable = useMemo(() => {
    const foundCompany = globalUserCompany.find(
      (guc) => guc._id === detail?.companyId,
    );
    const foundUser = foundCompany?.users.find(
      (u) => u.uid === userData?.user._id,
    );
    if (foundUser?.userRole === 'User') {
      return true;
    } else {
      return false;
    }
  }, [detail, globalUserCompany, userData]);

  const disabledButtonForNonPremiumUser = isServerLicense ? [] : globalUserCompany.filter(
    (guc) =>
      guc.licenceType !== 'premium' || new Date(guc.licenceValidTo) < new Date(),
  );

  const isCompanyVATincluded = (companyId: string) => {
    const foundCompany = globalUserCompany.find((guc) => guc._id === companyId);

    return foundCompany?.VATincluded;
  };

  return (
    <Dialog
      title={detail?.description ?? <Trans i18nKey="detail.detailTitle" />}
      onClose={onClose}
    >
      <>
        <Box sx={{ width: '100%' }} data-testid="detail-hardware-modal-content">
          <Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
              sx={{ width: '100%' }}
            >
              <Tab
                sx={{ width: '50%' }}
                label={<Trans i18nKey="detail.tabDetail" />}
                {...a11yProps(0)}
              />
              <Tab
                sx={{ width: '50%' }}
                label={<Trans i18nKey="detail.tabHistory" />}
                {...a11yProps(1)}
              />
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Grid container>
              <TabPanelDetail
                dataDetail={detail}
                detail={detail}
                detailCompany={detailCompany}
                employee={employee}
                isCompanyVATincluded={isCompanyVATincluded(
                  detail?.companyId ?? '',
                )}
              />

              {showPaymentDialog && detailCompany ? (
                <PaymentDialog
                  companyData={detailCompany}
                  onSubmit={() => {
                    setCompanyUpdate?.(true);
                    setHardwareUpdate(true);
                    setShowPaymentDialog(!showPaymentDialog);
                  }}
                  onClose={() => setShowPaymentDialog(!showPaymentDialog)}
                />
              ) : null}

              <Grid
                container
                sx={{
                  width: '100%',
                  position: 'sticky',
                  left: '0px',
                  bottom: '-18px',
                  padding: '20px 0px 20px 0px',
                  background: 'white',
                }}
              >
                <Grid container spacing={1} marginBottom={1}>
                  <Grid
                    item
                    xs={12}
                    md={
                      detail?.type.smallEquipments === 'normal' ||
                      detail?.type.smallEquipments == null
                        ? 6
                        : 12
                    }
                  >
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      style={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      disabled={isEditable}
                      onClick={() => {
                        detail?.borrowedBy
                          ? setShowReturnDialog(!showRemoveDialog)
                          : setShowBorrowDialog(!showBorrowDialog);
                      }}
                    >
                      <Trans i18nKey="detail.borrowOrReturn" />
                    </Button>
                  </Grid>

                  {showBorrowDialog ? (
                    <BorrowDialog
                      values={detail}
                      onClose={() => setShowBorrowDialog(!showBorrowDialog)}
                      onSubmit={() => setShowBorrowDialog(!showBorrowDialog)}
                    />
                  ) : null}

                  {showReturnDialog ? (
                    <RemoveDialog
                      values={detail}
                      onClose={() => setShowReturnDialog(!showReturnDialog)}
                      onSubmit={() => setShowReturnDialog(!showReturnDialog)}
                    />
                  ) : null}

                  {detail?.type.smallEquipments === null ||
                  detail?.type.smallEquipments === 'normal' ? (
                    <Grid item xs={12} md={6}>
                      <Button
                        type="button"
                        color="secondary"
                        variant="contained"
                        style={{
                          boxShadow: 'none',
                          width: '100%',
                        }}
                        disabled={isEditable}
                        onClick={() => {
                          setOpenSticker(!openRevision);
                        }}
                      >
                        <Trans i18nKey="detail.printSticker" />
                      </Button>
                    </Grid>
                  ) : null}
                </Grid>

                <Grid container spacing={1} marginBottom={1}>
                  <Grid
                    item
                    xs={12}
                    md={12}
                  >
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      style={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      disabled={isEditable}
                      onClick={() => {
                        if (detail?.guideId) {
                          setOpenGuideDialog(!openGuideDialog);
                        } else {
                          setShowGuideDialog(true);
                        }
                      }}
                    >
                      <Trans i18nKey="detail.guide" />
                    </Button>
                  </Grid>
                </Grid>

                {showGuideDialog ? (
                  <GuideDialog
                    onClose={() => setShowGuideDialog(false)}
                    data={detail!}
                    openStickerPrint={() => {
                      setStickerValue('Guide');
                      setOpenGuideDialog(!openGuideDialog);
                    }}
                  />
                ) : null}

                <Grid container spacing={1} marginBottom={1}>
                  <Grid item xs={12} md={6}>
                    <Button
                      type="button"
                      color="primary"
                      variant="contained"
                      style={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      disabled={isEditable}
                      onClick={() => {
                        const unPaidPremium =
                          disabledButtonForNonPremiumUser.filter(
                            (f) => f._id === detail?.companyId,
                          ).length > 0;
                        if (unPaidPremium) {
                          setShowPaymentDialog(!showPaymentDialog);
                          enqueueSnackbar(
                            <Trans i18nKey="notifications.fileManagementOnlyForPremiumCompanies" />,
                            {
                              variant: 'error',
                            }
                          );
                          return;
                        }
                        setOpenFileDialog(!openFileDialog);
                      }}
                    >
                      <Trans i18nKey="detail.document" />
                    </Button>
                  </Grid>

                  {openFileDialog ? (
                    <FileDialog
                      hwId={detail?._id}
                      onClose={() => setOpenFileDialog(!openFileDialog)}
                    />
                  ) : null}

                  <Grid item xs={12} md={6}>
                    <Button
                      type="button"
                      color="secondary"
                      variant="contained"
                      style={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      disabled={isEditable}
                      onClick={() => {
                        const unPaidPremium =
                          disabledButtonForNonPremiumUser.filter(
                            (f) => f._id === detail?.companyId,
                          ).length > 0;
                        if (unPaidPremium) {
                          setShowPaymentDialog(!showPaymentDialog);
                          enqueueSnackbar(
                            <Trans i18nKey="notifications.revisionOnlyForPremiumCompanies" />,
                            {
                              variant: 'error',
                            },
                          );
                          return;
                        }
                        setOpenRevision(!openRevision);
                      }}
                    >
                      <Trans i18nKey="detail.confirmRevision" />
                    </Button>
                  </Grid>

                  {openRevision ? (
                    <RevisionDialog
                      values={detail}
                      onClose={() => setOpenRevision(!openRevision)}
                      onSubmit={() => setOpenRevision(!openRevision)}
                    />
                  ) : null}
                </Grid>

                <Grid container spacing={1} marginBottom={1}>
                  <Grid item xs={6}>
                    <Button
                      type="button"
                      color="error"
                      variant="contained"
                      style={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      disabled={isEditable}
                      onClick={() => setShowRemoveDialog(detail?._id)}
                    >
                      <Trans i18nKey="detail.delete" />
                    </Button>

                    {showRemoveDialog ? (
                      <AlertDialog
                        onClose={() => closeAlertDialog()}
                        onDelete={() => {
                          if (detail !== undefined) {
                            handleDeleteItem(detail);
                          }
                        }}
                      />
                    ) : null}
                  </Grid>
                  <Grid item xs={6}>
                    <Button
                      type="button"
                      color="primary"
                      variant="outlined"
                      style={{
                        boxShadow: 'none',
                        width: '100%',
                      }}
                      disabled={isEditable}
                      onClick={() => setShowEditHwDialog(!showEditHwDialog)}
                    >
                      <Trans i18nKey="detail.edit" />
                    </Button>
                  </Grid>

                  {showEditHwDialog ? (
                    <EditHwDialog
                      values={detail}
                      onClose={() => setShowEditHwDialog(!showEditHwDialog)}
                      onSubmit={() => setShowEditHwDialog(!showEditHwDialog)}
                      editKey={editKey}
                    />
                  ) : null}
                </Grid>

                <Button
                  type="button"
                  variant="text"
                  style={{
                    boxShadow: 'none',
                    width: '100%',
                  }}
                  onClick={onClose}
                >
                  <Trans i18nKey="detail.close" />
                </Button>
              </Grid>

              {openSticker ? (
                <StickerDialog
                  data={[detail?._id ? detail._id : '']}
                  onClose={() => setOpenSticker(!openSticker)}
                  defaultStickerType={stickerValue ? stickerValue : undefined}
                />
              ) : null}

              {openGuideDialog ? (
                <GuideDetailDialog
                  guideId={detail?._id}
                  onClose={() => setOpenGuideDialog(!openGuideDialog)}
                  openGuideEditor={() => setShowGuideDialog(true)}
                />
              ) : null}
            </Grid>
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1}>
            <Grid container>
              <Grid xs={12}>
                {detail?.rentalHistory.map((h) => (
                  <Grid item key={h._id} xs={12}>
                    <Grid
                      container
                      sx={{ gridSx }}
                      padding={{ xs: '8px 0px 8px 0px', lg: '4px 0px 4px 0px' }}
                      borderBottom={{ xs: '1px solid #D9D9D9', lg: 'unset' }}
                    >
                      <Grid item xs={6} lg={4}>
                        {formatDate(new Date(h.createdAt), 'dd.MM.yyyy HH:mm')}
                      </Grid>
                      <Grid
                        item
                        xs={6}
                        lg={4}
                        textAlign={{ xs: 'right', lg: 'left' }}
                      >
                        {h.action === 'borrowed' ? (
                          <strong>
                            <Trans i18nKey="detail.borrowed" />
                          </strong>
                        ) : (
                          <Trans i18nKey="detail.returned" />
                        )}
                      </Grid>
                      <Grid item xs={12} lg={4}>
                        {!h.employee ? '---' : null}
                        {h.employee?.firstName} {h.employee?.lastName}
                      </Grid>
                    </Grid>
                  </Grid>
                ))}
              </Grid>

              <Grid
                container
                xs={12}
                sx={{
                  width: '100%',
                  position: 'sticky',
                  left: '0px',
                  bottom: '-18px',
                  padding: '16px 0px',
                  background: 'white',
                }}
              >
                <Grid item xs={12}>
                  <Button
                    type="button"
                    color="primary"
                    variant="contained"
                    style={{
                      boxShadow: 'none',
                      width: '100%',
                    }}
                    disabled={isEditable}
                    onClick={() => {
                      detail?.borrowedBy
                        ? setShowReturnDialog(!showRemoveDialog)
                        : setShowBorrowDialog(!showBorrowDialog);
                    }}
                  >
                    <Trans i18nKey="detail.borrowOrReturn" />
                  </Button>
                </Grid>

                {showBorrowDialog ? (
                  <BorrowDialog
                    values={detail}
                    onClose={() => setShowBorrowDialog(!showBorrowDialog)}
                    onSubmit={() => setShowBorrowDialog(!showBorrowDialog)}
                  />
                ) : null}

                {showReturnDialog ? (
                  <RemoveDialog
                    values={detail}
                    onClose={() => setShowReturnDialog(!showReturnDialog)}
                    onSubmit={() => setShowReturnDialog(!showReturnDialog)}
                  />
                ) : null}

                <Grid item xs={12}>
                  <Button
                    type="button"
                    variant="text"
                    style={{
                      boxShadow: 'none',
                      width: '100%',
                    }}
                    onClick={onClose}
                  >
                    <Trans i18nKey="detail.close" />
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CustomTabPanel>
        </Box>
      </>
    </Dialog>
  );
};
