import React, { FC, ReactText, useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Trans } from 'react-i18next';
import { format } from 'date-fns';

import MaterialTable, { Column } from '@material-table/core';

import { CompanyContext, HardwareContext, UserContext } from '../contexts';
import { useMaterialTableLocalization } from '../hooks';
import {
  useGetTableRows,
  createTableIcons,
  premiumLicenseValidityCheck,
  tableEnum,
  isServerLicense,
} from '../utils';
import { Company } from '../commonTypes';
import {
  CustomTablePagination,
  Link,
  StyledDiv,
  StyledTableColumnTitle,
  StyledTableTitle,
} from '../customStyledComponents';
import { PaymentDialog } from '../components';
import { Typography } from '@mui/material';

interface TableState {
  columns: Array<Column<Company>>;
  data: Company[];
}

interface CompanyStats {
  hwCount: number;
  companyId: string;
  notResolvedTaskCount?: number;
  taskCount?: number;
  countBorrowedEquipment?: number;
  countNotConfirmedEquipment?: number;
}

export const CompanyPreviewTable: FC = () => {
  const { globalUserCompany, refreshCompany } = useContext(CompanyContext);
  const { setFilterParams } = useContext(HardwareContext);
  const { changeTableRows, userData } = useContext(UserContext);
  const [showPaymentDialog, setShowPaymentDialog] = useState<ReactText>();
  const [companyData, setCompanyData] = useState<Company>();
  const [companyStats, setCompanyStats] = useState<CompanyStats[]>([]);

  const history = useHistory();
  const localization = useMaterialTableLocalization();
  const companyPreviewTableIcons = createTableIcons('company-preview');

  useEffect(() => {
    const get = async () => {
      const response = await fetch('/api/companies/company/stats', {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${userData!.user.token}`,
        },
        method: 'GET',
      });
      if (response.ok) {
        setCompanyStats(await response.json());
      }
    };
    get();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paymentDialogSubmit = () => {
    refreshCompany?.();
    setShowPaymentDialog(undefined);
  };

  const findCompanyData = (row: Company) => {
    const foundCompany = globalUserCompany?.find(
      (comp) => comp._id === row._id
    );

    if (foundCompany) {
      setCompanyData(foundCompany);
    }
  };

  const handleRedirect = (companyId: string, param: boolean | null) => {
    const filterParamsData = `?companyId=${companyId}&param=${param}`;
    history.push(`/hardware-list/${filterParamsData}`);
    setFilterParams({ companyId, param });
  };

  const getCompanyStats = (companyId: string) => {
    return companyStats.find((cs) => cs.companyId === companyId);
  };

  const dataTable: TableState = {
    columns: [
      {
        title: (
          <StyledTableColumnTitle>
            <Trans i18nKey="tables.companyPreviewTable.companyName" />
          </StyledTableColumnTitle>
        ),
        field: 'companyName',
        render: (row) => {
          return (
            <Link onClick={() => history.push(`/company/${row._id}`)}>
              {row?.companyName || '--'}
            </Link>
          );
        },
      },
      {
        title: (
          <StyledTableColumnTitle>
            <Trans i18nKey="tables.companyPreviewTable.countEquipment" />
          </StyledTableColumnTitle>
        ),
        field: 'countEquipment',
        render: (row) => {
          const count = getCompanyStats(row._id)?.hwCount;
          return (
            <Link onClick={() => handleRedirect(row._id, null)}>{count}</Link>
          );
        },
      },
      isServerLicense
        ? { hidden: true }
        : {
            title: (
              <StyledTableColumnTitle>
                <Trans i18nKey="tables.companyPreviewTable.countBorrowedEquipment" />
              </StyledTableColumnTitle>
            ),
            field: 'countBorrowedEquipment',
            render: (row) => {
              const count = getCompanyStats(row._id)?.countBorrowedEquipment;
              return (
                <Link onClick={() => handleRedirect(row._id, true)}>
                  {count}
                </Link>
              );
            },
          },
      isServerLicense
        ? {
            title: (
              <StyledTableColumnTitle>
                <Trans i18nKey="tables.companyPreviewTable.countAllTasks" />
              </StyledTableColumnTitle>
            ),
            field: 'countAllTasks',
            render: (row) => {
              const count = getCompanyStats(row._id)?.taskCount;
              return <Typography>{count}</Typography>;
            },
          }
        : { hidden: true },
      isServerLicense
        ? {
            title: (
              <StyledTableColumnTitle>
                <Trans i18nKey="tables.companyPreviewTable.countUnresolvedTasks" />
              </StyledTableColumnTitle>
            ),
            field: 'countUnresolvedTasks',
            render: (row) => {
              const count = getCompanyStats(row._id)?.notResolvedTaskCount;
              return <Typography>{count}</Typography>;
            },
          }
        : { hidden: true },

      isServerLicense
        ? { hidden: true }
        : {
            title: (
              <StyledTableColumnTitle>
                <Trans i18nKey="tables.companyPreviewTable.countNotConfirmedEquipment" />
              </StyledTableColumnTitle>
            ),
            field: 'countNotConfirmedEquipment',
            render: (row) => {
              const count = getCompanyStats(
                row._id
              )?.countNotConfirmedEquipment;
              return (
                <Link onClick={() => handleRedirect(row._id, false)}>
                  {count}
                </Link>
              );
            },
          },
      isServerLicense
        ? { hidden: true }
        : {
            title: (
              <StyledTableColumnTitle>
                <Trans i18nKey="tables.companyPreviewTable.validityOfLicence" />
              </StyledTableColumnTitle>
            ),
            field: 'licenceValidTo',
            render: (row) => {
              const date = row?.licenceValidTo
                ? format(new Date(row?.licenceValidTo), 'dd.MM.yyyy')
                : undefined;
              const licenseStatus = premiumLicenseValidityCheck(
                row?.licenceValidTo
              );
              return (
                <StyledDiv
                  style={{
                    width: '100px',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  {date || '--'}
                  <div
                    onClick={() => {
                      if (
                        licenseStatus.invalidLicense ||
                        licenseStatus.upcomingInvalidLicense
                      ) {
                        findCompanyData(row);
                        setShowPaymentDialog(row._id);
                      }
                    }}
                  >
                    {licenseStatus.dot}
                  </div>
                </StyledDiv>
              );
            },
          },
    ],
    data: [],
  };

  return (
    <div>
      <MaterialTable
        title={
          <StyledTableTitle>
            <Trans i18nKey="tables.companyPreviewTable.description" />
          </StyledTableTitle>
        }
        localization={localization}
        icons={companyPreviewTableIcons}
        columns={dataTable.columns}
        data={globalUserCompany ?? []}
        style={{
          fontSize: '13px',
        }}
        components={{
          Pagination: CustomTablePagination,
        }}
        options={{
          actionsColumnIndex: -1,
          emptyRowsWhenPaging: false,
          pageSize: useGetTableRows(tableEnum.companyPreviewTable),
        }}
        onRowsPerPageChange={(value) => {
          changeTableRows?.(tableEnum.companyPreviewTable, value);
        }}
      />
      {showPaymentDialog ? (
        <PaymentDialog
          companyData={companyData!}
          onSubmit={() => paymentDialogSubmit()}
          onClose={() => setShowPaymentDialog(undefined)}
        />
      ) : null}
    </div>
  );
};
